import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrMaskerModule } from 'br-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { AgricultorModule } from './pages/agricultor/agricultor.module';
import { LancarRecebimentoModule } from './pages/lancar-recebimento/lancar-recebimento.module';
import { LandingPageBaixaModule } from './pages/landing-page-baixa/landing-page-baixa.module';
import { LandingPageModule } from './pages/landing-page/landing-page.module';
import { LoginAdmModule } from './pages/login-adm/login-adm.module';
import { ProdutorModule } from './pages/produtor/produtor.module';
import { RegistroComprasModule } from './pages/registro-compras/registro-compras.module';
import { TraderCopyModule } from './pages/trader copy/trader-copy.module';
import { TraderModule } from './pages/trader/trader.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrMaskerModule,
    LandingPageModule,
    LoginAdmModule,
    TraderModule,
    TraderCopyModule,
    RegistroComprasModule,
    LancarRecebimentoModule,
    AgricultorModule,
    ProdutorModule,
    ComponentsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LandingPageBaixaModule,
    SharedModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide:  DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule { }
