import { Component, OnInit } from '@angular/core';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Movement } from 'src/app/interfaces/movement';
import { MovementService } from 'src/app/services/movement.service';
import { IbgeDataServiceService } from 'src/app/services/ibge-data-service.service';
import { urlSite, environment } from 'src/environments/environment';
import { QrCodeService } from 'src/app/services/qr-code.service';

declare var google: any;

interface farmForGroup {
  nameGroup: string;
  producers: Enterprise[]
}

interface itensForPart {
  itemBlockId: string;
  itens: TrackableItem[]
}

interface placesSubGroup {
  nameGroup: string;
  placesSub: any[];
}

interface documentsForEnterprise {
  nameGroup: string;
  documents: [];
}

@Component({
  selector: 'app-trader',
  templateUrl: './trader.component.html',
  styleUrls: ['./trader.component.scss']
})

export class TraderComponent implements OnInit {

  trackableItems?: TrackableItem[];
  trackebleItem?: TrackableItem[];

  enterprise?: Enterprise;

  formEnterprise !: FormGroup;

  customerId!: string;

  dateRegister?: string;

  userId: string = "";

  urlLandingPage: string = "";
  flagGraph: boolean = false;
  urlQRCODE: string = "";
  carbono: number = 0;
  qtdEnterprise: number = 0;
  trackableItemShowed: any = [];
  biome: string = "";
  showedInfos: boolean = false;
  harvestShowed: string = "";
  trace: any;
  harvest: string[] = [];
  trackebleItemByHash: TrackableItem[] = [];
  totalAmount: number = 0;
  movements: Movement[] = [];
  producers: Enterprise[] = [];
  groups: string[] = [];
  groupsSelect: string[] = [];
  corporatesNames: string[] = [];
  tokens: string[] = [];
  flag: boolean = false;
  qtdproducers: number = 0;
  producersSelected: Enterprise[] = [];
  itemForPart: itensForPart[] = [];
  producersDividedGroup: farmForGroup[] = [];
  placesSubGroup: placesSubGroup[] = [];
  blockId: any[] = [];
  documents: any[] = [];
  documentsForEnterprise: documentsForEnterprise[] = [];
  itensBlockId: any[] = [];
  groupShowed: string = "";
  productor: string = "";
  trackablesSelected: any = [];
  corporateName: string = "";
  trackableItemSelected!: TrackableItem;
  document: string = "";
  transporte: number = 0;
  insumo: number = 0;
  mut: number = 0;
  operacao: number = 0;
  trackebleItensSub?: TrackableItem[] = [];
  car: string = "";
  costumerId: string = "";
  farm: string = "";
  farmAddres: any;
  provider: any;
  urlQrCodeTrader: string = "";
  registerDateGraph: string = "";
  regionOrigin: any;
  cpf: string = "";
  isLoading: boolean = false;
  culturas: string[] = [];

  providerDocumentList: string[] = [];


  talhaoList: any[] = [];

  talhaoQtdMedia: number = 0;
  talhaoQtdTotal: number = 0;
  selectedProvider: any;
  farmList: any[] = [];

  escopoGraph = {
    escopo1: 0,
    escopo2: 0,
    escopo3: 0,
  };

  constructor(
    private traceabilityService: TraceabilityService,
    private movementService: MovementService,
    private enterpriseService: EnterpriseService,
    private route: ActivatedRoute,
    private ibgeDataService: IbgeDataServiceService,
    private qrCodeService: QrCodeService,
  ) {
    this.formEnterprise = new FormGroup({
      group: new FormControl('', [Validators.required]),
      tokens: new FormControl('', [Validators.required]),
      farm: new FormControl('', [Validators.required]),
      harvest: new FormControl('', [Validators.required]),
      culture: new FormControl('', [Validators.required]),
      // document: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.urlQrCodeTrader = urlSite;

    this.getParams();
  }

  getParams() {
    this.route.queryParams.subscribe((params: any) => {
      this.userId = params['customerId'];
      if (this.userId) {
        sessionStorage.setItem('customerId', this.userId);
      }
    });
    if (!this.userId) {
      this.userId = sessionStorage.getItem("customerId") as string;
    }
    localStorage.setItem('traceId', this.userId);
    this.getEnterprise();
  }

  getValue(value: any) {
    return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);
  }

  async getEnterprise() {
    this.isLoading = true;
    await this.enterpriseService.getEnterpriseByCustomerId(this.userId).toPromise().then(enterprise => {
      if (enterprise != undefined) {
        this.enterprise = enterprise[0];
        this.getTrackableItem();
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  async getFarmAddress() {
    await this.enterpriseService.getEnterpriseByDocument(this.provider.document).subscribe((item: any) => {
      this.farmAddres = item[0]?.address;
    });
  }

  async getTrackableItem() {
    if (this.enterprise != undefined) {
      this.traceabilityService.getTraceByDocument(this.enterprise.document).toPromise().then(res => {
        this.trackebleItem = res;
        this.getDatas();
      });
    }
  }

  async getDatas() {
    this.providerDocumentList = [];
    this.trackebleItem?.forEach(async trackable => {
      await this.movementService.getTraceByHash(trackable.hash).toPromise().then(trackableByHash => {
        if (trackableByHash) {
          if (!this.trackebleItemByHash.some(t => t.hash === trackableByHash.hash)) {
            this.trackebleItemByHash.push(trackableByHash);
            trackableByHash.movements.forEach(async movement => {
              this.movements.push(movement);
              this.totalAmount += movement.amount;
              if (! this.providerDocumentList.some(document => document === movement.provider.document)) {
                this.providerDocumentList.push(movement.provider.document);
                await this.enterpriseService.getEnterpriseByDocument(movement.provider.document).toPromise().then(enterprise => {
                  if (enterprise && enterprise[0].corporateName != undefined) {
                    if (!this.producers.some(item => item.document === enterprise[0].document)){
                      this.producers.push(enterprise[0]);
                      movement.provider.places.map(place => {
                        if (!this.farmList.some(farm => farm.code === place.code)) {
                          this.farmList.push(place);
                        }
                      })
                    }
                    if (enterprise[0].economicGroup && !this.groups.includes(enterprise[0].economicGroup)) {
                      this.groups.push(enterprise[0].economicGroup);
                    }
                  }
                });
              }
            });
          }
        }
      });
    })
  }

  getItensSelected() {
    this.movements.forEach(movement => {
      if (this.document == movement.provider.document) {
        this.trackebleItem?.forEach(trackeble => {
          if (trackeble.hash == movement.destiny.hash && !this.trackablesSelected.includes(trackeble)) {
            this.trackablesSelected.push(trackeble);
            const culture = this.getFeatureByName(trackeble.item, 'CULTURA');
            if (culture && !this.culturas.includes(culture)) {
              this.culturas.push(culture);
            }
          }
        })
      }
    });
  }

  getHarvest() {
    this.showedInfos = false;
    this.showedInfos = false;
    this.formEnterprise.get('harvest')?.setValue('');
    this.formEnterprise.get('token')?.setValue('');
    this.formEnterprise.get('culture')?.setValue('');
    this.culturas = [];
    this.harvest = [];
    this.tokens = [];

    this.getDocument();
    this.harvest = [];
    // const producer = this.formEnterprise.get('document')?.value;
    const farm = this.formEnterprise.get('farm')?.value;
    this.trackablesSelected?.forEach((trackable: any) => {
      if (trackable.item.features) {
        trackable.item.features.forEach((feature: any) => {
          if ((feature.name === 'Ano Safra' || feature.name === 'ANO_SAFRA') && !this.harvest.includes(feature.value)) {
            this.harvest.push(feature.value);
          }
          if ((feature.name === 'CULTURA') && !this.culturas.includes(feature.value)) {
            this.culturas.push(feature.value);
          }
        })
      }
    })
  }

  getDocument() {
    this.document = "";
    const farm = this.formEnterprise.get('farm')?.value;
    this.provider = this.producers.find(provider => provider.places.find((place:any) => place.code === farm));
   this.document = this.provider.document;

    this.getItensSelected();
  }

  getTokens() {
    this.showedInfos = false;
    this.formEnterprise.get('token')?.setValue('');
    this.tokens = [];
    const harvest = this.formEnterprise.get('harvest')?.value;
    const culture = this.formEnterprise.get('culture')?.value;
    const filteredMovements = this.movements.filter(movement => {
      return ((this.getFeatureByName(movement.destiny.item, 'CULTURA') || this.getCultureName(movement.destiny.item.product.code)) === culture) &&
      ((this.getFeatureByName(movement.destiny.item, 'ANO_SAFRA') || this.getFeatureByName(movement.destiny.item.product, 'Ano Safra')) === harvest);
    });

    if (filteredMovements.length > 0) {
      filteredMovements.forEach(mov => {
        if (mov.provider.document == this.document) {
          this.trackablesSelected.forEach((trackable: any) => {
            if (trackable.hash == mov.destiny.hash && !this.tokens.includes(trackable.hash)) {
              this.tokens.push(trackable.hash);
            }
          })
        }
      })
    } else {
      this.showedInfos  = false;
    }

  }

  getTokenSelected() {
    this.showedInfos = false;
    const token = this.formEnterprise.get('tokens')?.value;
    //this.trackableItemSelected = this.formEnterprise.get('group')?.value;
    this.trackablesSelected?.forEach((trackable: any) => {
      if (trackable.hash == this.formEnterprise.get('tokens')?.value) {
        this.trackableItemSelected = trackable;
        this.dateRegister = trackable.creationDate;
        trackable.item.features.forEach((feature: any) => {
          if (feature.name == 'DATA_REGISTRO') {
            this.registerDateGraph = feature.value;
            this.registerDateGraph = this.registerDateGraph.slice(0, 10);
            this.registerDateGraph = this.registerDateGraph?.split('-').reverse().join('/');
          }
        })
      }
    });

    this.getBiome();
  }

  getBiome() {
    this.trackableItemSelected.item.features.forEach((feature: any) => {
      if (feature.name == 'BIOMA') {
        this.biome = feature.value;
      }
    })
  }

  showInfos() {
    this.showedInfos = false;
    // const document = this.formEnterprise.get('document')?.value;
    const token = this.formEnterprise.get('tokens')?.value;
    this.movements.forEach(movement => {
      if (movement.provider.document == this.provider.document && movement.destiny.hash === token) {
        this.provider.places.forEach((place: any) => {
          this.selectedProvider = movement;
          if (place.ruralEnvironmentalRegistry == movement.provider.places[0].code && movement.destiny.hash == this.trackableItemSelected.hash) {
            this.farm = place.nickname;
          }
        })
      }
    });

    this.groupShowed = this.formEnterprise.get('group')?.value;
    this.productor = this.formEnterprise.get('corporateName')?.value;
    this.harvestShowed = this.formEnterprise.get('harvest')?.value;
    this.getFarmAddress();

    this.getConsumerId();
    this.getCar();
    this.getDatasGraph();
    this.showedInfos = true;
  }

  getConsumerId() {
    this.producers.forEach(producer => {
      this.movements.forEach(movement => {
        if (movement.provider.document == producer.document && movement.destiny.hash == this.trackableItemSelected.hash) {
          this.customerId = producer.customerId;
        }
      });
    });
  }

  getLandingPageData() {
    let totalValue = 0;
    let farmList: any[] = [];
    let verificationDate = '';
    let cultura = '';
    let harvest = '';
    let biomes: any[] = [];
    let preservedArea = '';
    let soybeanArea = '';
    let nationalAverage = 0;
    let averageEstimate = 0;
    let namesBiomes: any[] = [];
    let qtdBiomes = 0;

    let divider = 0;
    let dividend = 0;
    let enterprise: any = null;

    this.isLoading = true;
    this.enterpriseService.getEnterpriseByCustomerId(this.customerId).subscribe(item => {
      this.isLoading = false;
      enterprise = item[0];
      divider = 0;
      dividend = 0;

      enterprise.places.map((place: any) => {
        if (place.type === 'FARM') {
          farmList.push({
            nickname: place.nickname,
            code: place.code,
            ruralEnvironmentalRegistry: place.ruralEnvironmentalRegistry,
          });
        }
      });
      totalValue = 0;
      this.traceabilityService.getTraceByDocument(enterprise.document).subscribe(trackeableItens => {
        trackeableItens[0].item.features.map(item => {
          if (item.name === 'DATA_REGISTRO') {
            verificationDate = item.value;
          }
          if (item.name === 'CULTURA') {
            cultura = item.value;
          }
          if (item.name === 'ANO_SAFRA') {
            harvest = item.value;
          }
        });

        trackeableItens.forEach(trackeableItem => {
          biomes.push(trackeableItem.item.features.filter(f => f.name === "BIOMA"));

          let featurePreservedArea = trackeableItem.item.features.filter(f => f.name === "AREA_PRESERVADA");
          preservedArea += parseFloat(featurePreservedArea[0].value);

          let soybean = trackeableItem.item.features.filter(f => f.name === "AREA_SOJA");
          soybeanArea += parseFloat(soybean[0].value);


          if (trackeableItem.item.features.some(f => f.name === "ANO_SAFRA" && f.value === harvest)) {
            totalValue += trackeableItem.item.receivedQuantity;
          }


          let emissaoCarbono = 0;
          //let areaSoja = 0;
          trackeableItem.item.features.forEach(feature => {
            if (feature.name === "QTD_TOTAL_EMISSÃO_CARBONO" || feature.name ==='QTD_TOTAL_EMISSÃO_CARBONO') {
              emissaoCarbono = parseFloat(feature.value);
            }
          });
          divider += trackeableItem.item.receivedQuantity;
          dividend += (emissaoCarbono * trackeableItem.item.receivedQuantity);

        })

        nationalAverage = dividend / divider;
        let feature = trackeableItens[0].item.features.filter(f => f.name === 'PEGADA_MEDIA_SOJA_COMUM')
        averageEstimate = parseFloat(feature[0].value);
        biomes.forEach(biome => {
          namesBiomes.push(biome[0].value);
        })

        let i = 0, biomesDiferents: string[] = [];
        biomesDiferents.push(namesBiomes[0]);

        namesBiomes.forEach(nameBiome => {
          if (namesBiomes[i] != nameBiome && !biomesDiferents?.includes(nameBiome)) {
            biomesDiferents.push(nameBiome);
          }
        })

        qtdBiomes = biomesDiferents?.length;
        let volumeTotal = 0;
        this.trackablesSelected.forEach((trackable: TrackableItem) => {
          volumeTotal += trackable.item.amount;
        });

        const pageData = {
          customerId : this.customerId,
          cultura: cultura,
          harvest: harvest,
          volumeTotal: volumeTotal,
          pegadaMediaGrupo: this.carbono,
          farmList: farmList,
          biomes: biomesDiferents,
          verificationDate: verificationDate,
        }

        this.qrCodeService.postQRData({ data: pageData }).subscribe((res : any) => {
          const token = res.id;
          this.urlLandingPage = `landing-page?data=${token}`;
          this.urlQRCODE = `${environment.apiURL}/qrcode?url=${this.urlQrCodeTrader}/landing-page?data=${token}`;
        });
      });
    }, error => {

    });
  }

  getCar() {
    this.movements.forEach(move => {
      if (move.destiny.hash == this.trackableItemSelected.hash) {
        this.car = move.provider.places[0].ruralEnvironmentalRegistry;
        this.getAddressByRegistry();
      }
    })
    this.getCpf();
  }

  onGroupChange() {
    this.showedInfos = false;
    this.formEnterprise.get('harvest')?.setValue('');
    this.formEnterprise.get('token')?.setValue('');
    this.formEnterprise.get('culture')?.setValue('');
    this.culturas = [];
    this.harvest = [];
    this.tokens = [];
    this.farmList = [];

    const group = this.formEnterprise.get('group')?.value;
    this.trackebleItemByHash.forEach(trackable => {
      trackable.movements.forEach(movement => {
        if (movement.provider.economicGroup === group) {
          movement.provider.places.map(place => {
            if (!this.farmList.some(farm => farm.code === place.code)) {
              this.farmList.push(place);
            }
          });
        }
      });
    });
  }

  getCpf() {
    this.movements.forEach(move => {
      if (move.destiny.hash == this.trackableItemSelected.hash) {
        let cpf = move.provider.document;
        if (cpf.length == 11) {
          this.cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
        }
        else {
          this.cpf = cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
        }
      }
    });
  }

  getFarmCode(hash: string) {
    let place: any;
    this.movements.forEach(movement => {
      if (movement.destiny.hash === hash) {
        movement.provider.places.map(placeItem => {
          if (placeItem.type && placeItem.type === 'FARM') {
            place = placeItem;
          }
        });
      }
    })
    if (place && place.ruralEnvironmentalRegistry) {
      return place.ruralEnvironmentalRegistry;
    }
  }

  async getDatasGraph() {

    let valorSumTransporte = 0, dividendCarbono = 0, emissaoCarbono = 0, insumo = 0, mut = 0, operacao = 0, valorSumInsumo = 0, valorSumOperacao = 0, valorSumMUT = 0, dividendTransporte = 0, dividendInsumo = 0, dividendOperacao = 0, dividendMut = 0, vol = 0;

    await this.traceabilityService.getTraceByDocument(this.document).toPromise().then(traceability => {
      this.trackebleItensSub = traceability;
    });
    let divisor = 0;
    this.talhaoList = [];
    const culture = this.formEnterprise.get('culture')?.value;
    const harvest = this.formEnterprise.get('harvest')?.value;
    const filteredTrackables = this.trackebleItensSub?.filter(item => this.getFeatureByName(item.item, 'CULTURA') === culture &&  this.getFeatureByName(item.item, 'ANO_SAFRA') === harvest);
    filteredTrackables?.forEach(trackeble => {
      trackeble.item.features.forEach(feature => {
        if (!(this.talhaoList.some(item => item === trackeble.item.place))) {
          this.talhaoList.push(trackeble.item.place);
        }
        if (feature.name == 'QTD_C02_EQ_TRANSPORTE') {
          valorSumTransporte = parseFloat(feature.value);
        } else if (feature.name == 'QTD_C02_EQ_INSUMO') {
          valorSumInsumo = parseFloat(feature.value);
        } else if (feature.name == 'QTD_C02_EQ_OPERAÇÃO') {
          valorSumOperacao = parseFloat(feature.value);
        } else if (feature.name == 'QTD_C02_EQ_MUT') {
          valorSumMUT = parseFloat(feature.value);
        } else if (feature.name == "QTD_TOTAL_EMISSÃO_CARBONO") {
          emissaoCarbono = parseFloat(feature.value);
        } else if (feature.name == "QTD_C02_ESCOPO_UM") {
          this.escopoGraph.escopo1 = parseFloat(feature.value);
        } else if (feature.name == "QTD_C02_ESCOPO_DOIS") {
          this.escopoGraph.escopo2 = parseFloat(feature.value);
        } else if (feature.name == "QTD_C02_ESCOPO_TRES") {
          this.escopoGraph.escopo3 = parseFloat(feature.value);
        }
      });
      this.talhaoQtdTotal = filteredTrackables?.length as number;
      this.talhaoQtdMedia =  this.talhaoList.length;
      vol = trackeble.item.receivedQuantity;
      divisor = divisor + trackeble.item.receivedQuantity;

      dividendTransporte += valorSumTransporte * vol;
      dividendInsumo += valorSumInsumo * vol;
      dividendOperacao += valorSumOperacao * vol;
      dividendMut += valorSumMUT * vol;
      dividendCarbono += emissaoCarbono * vol;
    });

    this.transporte = dividendTransporte / divisor;
    this.insumo = dividendInsumo / divisor;
    this.operacao = dividendOperacao / divisor;
    this.mut = dividendMut / divisor;
    this.carbono = dividendCarbono / divisor;

    this.flagGraph = true;
    this.getLandingPageData();
  }

  getCultureName(productCode: string): string {
    let culture = '-'
    if (productCode) {
      const splittedCode = productCode.split('-');
      culture = splittedCode[0];
    }
    return culture;
  }
  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: null,
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  getAddressByRegistry() {
    if (this.car) {
      const car2 = this.car.split('-');
      const id = car2[1];
      this.ibgeDataService.getAreaById(id).subscribe(item => {
        this.regionOrigin = item;
      });
    }
  }
}
