import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgricultorComponent } from '../app/pages/agricultor/agricultor.component';
import { TraderComponent } from './pages/trader/trader.component';
import { TraderCopyComponent } from './pages/trader copy/trader-copy.component';
import { RegistroComprasComponent } from '../app/pages/registro-compras/registro-compras.component'
import { LancarRecebimentoComponent } from './pages/lancar-recebimento/lancar-recebimento.component';
import { ProdutorComponent } from './pages/produtor/produtor.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LandingPageBaixaComponent } from './pages/landing-page-baixa/landing-page-baixa.component';
import { AuthGuard } from './guard/auth.guard';
import { UserNotFoundComponent } from './components/user-not-found/user-not-found.component';
import { LoginAdmComponent } from './pages/login-adm/login-adm.component';
import { TraceabilityReportComponent } from './components/traceability-report/traceability-report.component';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginAdmComponent },
  { path: 'user-not-found', component: UserNotFoundComponent },
  { path: 'landing-page', component: LandingPageComponent },
  { path: 'landing-page-baixa', component: LandingPageBaixaComponent },
  { path: 'produtor-inicio', component: AgricultorComponent, canActivate: [AuthGuard] },
  { path: 'trader', component: TraderComponent, canActivate: [AuthGuard] },
  { path: 'trader-copy', component: TraderCopyComponent, canActivate: [AuthGuard] },
  { path: 'registro-compras', component: RegistroComprasComponent, canActivate: [AuthGuard] },
  { path: 'lancar-recebimento', component: LancarRecebimentoComponent, canActivate: [AuthGuard] },
  { path: 'produtor', component: ProdutorComponent, canActivate: [AuthGuard] },
  { path: 'laudo-rastreabilidade', component: TraceabilityReportComponent, canActivate: [AuthGuard]},
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
