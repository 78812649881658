import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import jwtDecode from 'jwt-decode';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalTypeUserComponent } from 'src/app/components/modal-type-user/modal-type-user.component';

@Component({
  selector: 'app-login-adm',
  templateUrl: './login-adm.component.html',
  styleUrls: ['./login-adm.component.scss']
})
export class LoginAdmComponent implements OnInit {

  showLogin: boolean = false;
  msgFamilia: string = "Bem-vindo a página";
  modalOpen: boolean = false;
  formLogin: FormGroup;
  formUser: FormGroup;
  formTrader: FormGroup;
  formProducer: FormGroup;
  email: string = "";
  password: string = "";
  token: any;
  decodedToken: any;
  modalUser: boolean = false;
  modalProducer: boolean = false;
  modalTrader: boolean = false;
  groups: any;
  typeUser: string | null = "";
  producers: any[] = [];
  producerSelected: any;
  traders: any;
  traderSelected: any;
  selectedValue: string = "";
  allProducers: any;
  arrayOptions = [
    { op: 'Trader' },
    { op: 'Produtor' }
  ]
  error: boolean = false;
  loading: boolean = false;

  constructor(public fb: FormBuilder,
    private route: ActivatedRoute,
    private enterpriseService: EnterpriseService,
    private authService: AuthService,
    private router: Router
  ) {
    this.formLogin = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.formUser = fb.group({
      userType: ['', [Validators.required]],
    });

    this.formTrader = fb.group({
      trader: ['', [Validators.required]]
    })

    this.formProducer = fb.group({
      producer: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    sessionStorage.setItem('token', '');
    sessionStorage.setItem('passphrase', '');
    sessionStorage.setItem('customerId', '');
    sessionStorage.setItem("user", "user");
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.token) {
      let decodedToken: any;
      try {
        decodedToken = jwtDecode(this.token);
      } catch (error) {
        alert('Token Inválido');
        this.router.navigate(['/login']);
        return;
      }
      const passphrase = decodedToken.passphrase;
      const customerId = decodedToken.customerId;
      try {
        this.decodedToken = jwtDecode(passphrase);
      } catch (error) {
        alert('Token Inválido');
        this.router.navigate(['/login']);
        return;
      }

      if (this.token && customerId) {
        sessionStorage.setItem('token', passphrase);
        sessionStorage.setItem('passphrase', this.token);
        sessionStorage.setItem('access_token', passphrase);
        sessionStorage.setItem('customerId', customerId);
        sessionStorage.setItem('userId', customerId);
        if (this.decodedToken.authorities.includes('ROLE_OWNER')) {
          sessionStorage.setItem("typeUser", "PRODUTOR");
          this.router.navigate(['/produtor'], { queryParams: { customerId: customerId } });
        }
        if (this.decodedToken.authorities.includes('ROLE_TRADER')) {
          sessionStorage.setItem("typeUser", "TRADER");
          this.router.navigate(['/trader'], { queryParams: { customerId: customerId } });
        }
      } else {
        this.router.navigate(['/user-not-found']);
      }

    } else {
      this.showLogin = true;
    }
  }

  close(event: any) {
    if(event === 'voltar') {
      this.modalOpen = true;
      this.modalProducer = false;
      this.modalTrader = false;
    } else {
      this.modalOpen = false;
      this.modalProducer = false;
      this.modalTrader = false;
    }
  }

  receberDados(dados: string) {
    alert(dados);
    // Faça o que quiser com os dados recebidos do componente filho
  }

  submitForm() {
    this.email = this.formLogin.get('email')?.value;
    this.password = this.formLogin.get('password')?.value;
    this.loading = true;
    this.authService.login(this.email, this.password).subscribe(
      res => {
        this.token = res;
        if (this.token) {
          const decodedToken: any = jwtDecode(this.token);
          const passphrase = this.token;
          const customerId = decodedToken.customerId;
          this.decodedToken = decodedToken;

          if (this.decodedToken.authorities.includes('ROLE_ADMINBAYER')) {
            this.openModalTypeUser();
          } else if (this.decodedToken.authorities.includes('ROLE_OWNER')) {
            sessionStorage.setItem("typeUser", "PRODUTOR");
            this.router.navigate(['/produtor'], { queryParams: { customerId: customerId } });
            this.showLogin = false;
          } else if (this.decodedToken.authorities.includes('ROLE_TRADER')) {
            sessionStorage.setItem("typeUser", "TRADER");
            this.router.navigate(['/trader'], { queryParams: { customerId: customerId } });
            this.showLogin = false;
          } else if (this.decodedToken.authorities.includes('ROLE_ADMIN')) {
            sessionStorage.setItem("user", "user");
            this.router.navigate(['/registro-compras'], { queryParams: { customerId: customerId } });
            this.showLogin = false;
          }
          sessionStorage.setItem('token', this.token);
          sessionStorage.setItem('passphrase', passphrase);
          sessionStorage.setItem('customerId', customerId);
          //this.router.navigate(['/'], { queryParams: { id: customerId } });
          //this.showLogin = false;
          this.loading = false;
        } else {
          this.loading = false;
          alert('usuário ou senha incorretos!');
        }
      },
      error => {
        this.loading = false;
        alert('Não foi possível realizar o login!');
      }
    );

  }

  getAllTraders() {
    this.loading = true;
    this.enterpriseService.getEnterpriseByOperationalType('TRADER').subscribe(traders => {
      this.traders = traders;
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  openModalTypeUser() {

    this.modalOpen = true;
    //this.modalRef = this.modalService.show(ModalTypeUserComponent);
    //this.getTypeUser();
  }

  getAllGroups() {
    this.loading = true;
    this.enterpriseService.getAllGroups().subscribe(groupsReturned => {
      this.groups = groupsReturned;
      this.getProducers();
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  /*reciverFeedback(respostaFilho: any) {
  }*/

  toPageTrader() {
    let userName = this.formTrader.get('trader')?.value;
    let traderSelected = this.traders.filter((trader: any) => trader.corporateName == userName);
    sessionStorage.setItem("user", "userRoot");
    this.router.navigate(
      ['/registro-compras'],
      { queryParams: { customerId: traderSelected[0].customerId } }
    );


  }

  getTraderSelected(trader: any) {
    this.traderSelected = trader;
  }

  async getProducers() {
    await this.groups.forEach((group: any) => {
      this.enterpriseService.getDatasGroupByEnterpriseCode(group).subscribe(producers => {
        this.producers.push(producers);
        this.producers = this.producers.flat(1);
      })
    })

  }

  toPageProducer() {
    let userName = this.formProducer.get('producer')?.value;
    let producerSelected = this.producers.filter((producer: any) => producer.corporateName == userName);
    sessionStorage.setItem("user", "userRoot");
    this.router.navigate(
      ['/produtor'],
      { queryParams: { customerId: producerSelected[0].customerId } }
    );

  }


  getTypeUser() {
    //alert(user);
    this.typeUser = this.formUser.get('userType')?.value;

  }


  changeModal(dados: string) {
    if (dados != 'close') {
      this.typeUser = dados;

      if (this.typeUser == 'Produtor') {
        this.modalOpen = false;
        this.modalProducer = true;
        this.getAllGroups();
      }
      else if (this.typeUser == 'Trader') {
        this.modalOpen = false;
        this.modalTrader = true;
        this.getAllTraders();
      }
    }
    else {
      this.modalOpen = false;
    }
  }

  getProducerSelected(producer: any) {
    this.producerSelected = producer;
  }
}
