import { Component, OnInit } from '@angular/core';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { MovementService } from 'src/app/services/movement.service';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { Movement } from 'src/app/interfaces/movement';
import { TrackableMovements } from 'src/app/interfaces/trackablemovements';
import { urlSite, environment } from 'src/environments/environment';
import { QrCodeService } from 'src/app/services/qr-code.service';
import { Place } from 'src/app/interfaces/place';

@Component({
  selector: 'app-produtor',
  templateUrl: './produtor.component.html',
  styleUrls: ['./produtor.component.scss']
})
export class ProdutorComponent implements OnInit {
  firstEnterprise?: Enterprise;
  formFilter: FormGroup;
  userId?: string | null;
  selectedOption: any;
  returnedTrace: TrackableItem[] = [];
  volumeSold: number = 0;
  saldoAtual: number = 0;
  volumeTotal: number = 0;
  traceByToken: any;
  harvest: any;
  movements: Movement[] = [];
  customers: Enterprise[] = [];
  findMovementFlag = true;
  estimativaPegaMedia: number = 0;
  somaPegadaMedia: number = 0;
  trackableMovementsData: TrackableMovements[] = [];
  farms: any = [];

  talhaoList: string[] = [];
  harvestList: string[] = [];
  culturesList: string[] = [];
  trackableItensList: TrackableItem[] | undefined = [];
  trackableItensFiltered: TrackableItem[] | undefined = [];
  trackableItensListFiltered: TrackableItem[] | undefined = [];
  search: boolean = false;


  isLoading: boolean = false;
  producers: any;

  urlLandingPage: string = "";
  urlQRCODE: string = "";
  urlQrCodeTrader: string = "";

  constructor(
    private traceabilityService: TraceabilityService,
    private enterpriseService: EnterpriseService,
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    private movementService: MovementService,
    private qrCodeService: QrCodeService,
    private route: ActivatedRoute,
  ) {
    this.formFilter = new FormGroup({
      producer: new FormControl('', [Validators.required]),
      farm: new FormControl('', [Validators.required]),
      harvest: new FormControl('', [Validators.required]),
      culture: new FormControl('', [Validators.required]),
      talhao: new FormControl('', [Validators.required]),
    });
  }

  getUrlApi() {
    return environment.apiURL;
  }

  getUrl() {
    return urlSite;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.userId = params['customerId'];
      if (this.userId) {
        sessionStorage.setItem('customerId', this.userId);
      }
    });
    if (!this.userId) {
      this.userId = sessionStorage.getItem('customerId');
    }
    localStorage.setItem('traceId', this.userId as string);
    this.isLoading = true;
    this.enterpriseService.getEnterpriseByCustomerId(this.userId).subscribe(item => {
      this.isLoading = false;
      this.firstEnterprise = item[0];
      if (this.firstEnterprise.economicGroup) {
        this.isLoading = true;
        this.enterpriseService.getDatasGroupByEnterpriseCode(this.firstEnterprise.economicGroup).subscribe(res => {
          this.isLoading = false;
          this.producers = res;
        }, error => {
          this.isLoading = false;
        })
      }
      sessionStorage.setItem('customerId', this.firstEnterprise.customerId);
    }, error => {
      this.isLoading = false;
    });
  }

  async submitForm() {
    this.search = false;
    this.formFilter.markAllAsTouched();
    if (this.formFilter.valid) {
      this.volumeTotal = 0;
      this.volumeSold = 0;
      this.trackableItensListFiltered = [];

      const harvest = this.formFilter.get('harvest')?.value;
      const culture = this.formFilter.get('culture')?.value;
      const talhao = this.formFilter.get('talhao')?.value;
      this.trackableItensListFiltered = this.trackableItensList?.filter(movement => {
        return ((this.getFeatureByName(movement.item, 'NOME_TALHAO') || this.getFeatureByName(movement.item, 'FIELD_NAME')) === talhao) &&
          ((this.getFeatureByName(movement.item, 'CULTURA') || this.getCultureName(movement.item.product.code)) === culture) &&
          ((this.getFeatureByName(movement.item, 'ANO_SAFRA') || this.getFeatureByName(movement.item.product, 'Ano Safra')) === harvest)
      });

      if (this.trackableItensListFiltered && this.trackableItensListFiltered.length > 0) {
        await this.getMovementsInfo();
        this.search = true;
      } else {
        this.search = true;
      }
    }
  }

  async getMovementsInfo() {
    this.trackableMovementsData = [];
    this.somaPegadaMedia = 0;
    this.estimativaPegaMedia = 0;
    const item = this.trackableItensListFiltered;

    let divider = 0;
    let dividend = 0;
    if (item != undefined) {
      for (const trackable of item) {
        this.volumeTotal += trackable.item.receivedQuantity;
        this.saldoAtual = this.volumeTotal;

        this.isLoading = true;
        const trackableByToken = await this.movementService.getMovementByToken(trackable.itemBlockId).toPromise();
        this.isLoading = false;
        this.findMovementFlag = true;
        this.returnedTrace.push(trackable);

        if (trackableByToken != undefined) {
          for (const movement of trackableByToken.movements) {
            if (!this.movements.some(m => m.hash === movement.hash)) {
              this.movements.push(movement);
              this.volumeSold += movement.amount;
              this.saldoAtual -= movement.amount;
            }

            const enterprise = await this.enterpriseService.getEnterpriseByDocument(movement.customer.document).toPromise();
            if (enterprise != undefined) {
              this.customers.push(enterprise[0]);
            }
          };
        }
        const trackableMovements: TrackableMovements = {
          trackable: trackable,
          movements: this.movements,
          customers: this.customers
        }

        this.trackableMovementsData.push(trackableMovements);

        this.movements = [];
        this.customers = [];

        this.addNItem();

        if (this.returnedTrace.length > 0) {
          localStorage.setItem('itemBlockId', this.returnedTrace[0].itemBlockId);

          let features = this.returnedTrace[0].item.product.features;

          this.harvest = features.filter((feature: any) => { return feature.name == 'Ano Safra' });
        } else {
          this.findMovementFlag = false;
        }

        let emissaoCarbono = 0;
        let areaSoja = 0;
        trackable.item.features.forEach(feature => {
          if (feature.name == "QTD_TOTAL_EMISSÃO_CARBONO") {
            emissaoCarbono = parseFloat(feature.value);
          }
          if (feature.name == "AREA_SOJA") {
            areaSoja = parseFloat(feature.value);
            divider += areaSoja;
          }
        });
        dividend += (emissaoCarbono * trackable.item.receivedQuantity);

      };
    }
    this.estimativaPegaMedia = dividend / this.volumeTotal;
    this.getLandingPageData();
  }

  onChangeProducer() {
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');
    this.search = false;

    const producer = this.producers.find((item: any) => item.id === this.formFilter.get('producer')?.value);
    this.farms = [];
    if (producer) {
      producer.places.forEach((element: any) => {
        if (element.type === 'FARM') {
          if (!this.farms.some((farm: Place) => farm.code === element.code)) {
            this.farms.push(element);
          }
        }
      });
    }
  }
  async onChangeCropYear() {
    this.culturesList = [];
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');
    this.search = false;
    const selected = this.formFilter.get("harvest")?.value;
    this.trackableItensList?.forEach(item => {
      const feature: any = this.getFeatureByName(item.item, "ANO_SAFRA")
      if (feature === selected) {
        const culture = item.item && (this.getFeatureByName(item.item, 'CULTURA')) ? this.getFeatureByName(item.item, 'CULTURA') : '';
        if (culture) {
          if (!this.culturesList.includes(culture)){
            this.culturesList.push(culture);
          }
        }
      }
    });
  }


  async onChangeCulture() {
    this.formFilter.get('talhao')?.setValue('');
    this.talhaoList = [];
    this.search = false;
    const culture = this.formFilter.get("culture")?.value;
    const harvest = this.formFilter.get('harvest')?.value;

    this.trackableItensList?.forEach(item => {
      if (culture === this.getFeatureByName(item.item, "CULTURA") && harvest === this.getFeatureByName(item.item, "ANO_SAFRA")) {
        const talhao = this.getFeatureByName(item.item, 'NOME_TALHAO');
        if (talhao) {
          this.talhaoList.push(talhao);
        }
      }
    });
  }



  async onChangeSelect() {
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');
    this.search = false;
    this.selectedOption = this.farms.find((farm: any) => farm.code === this.formFilter.get('farm')?.value);
    this.returnedTrace = [];
    this.movements = [];
    this.customers = [];
    this.harvest = null;
    this.volumeSold = 0;
    this.volumeTotal = 0;
    this.saldoAtual = 0;


    this.isLoading = true;
    const producer = this.producers.filter((item: any) => item.id === this.formFilter.get('producer')?.value);
    const item = await this.traceabilityService.getTraceByPlaceCode(this.selectedOption.code, producer[0].economicGroup).toPromise();
    this.isLoading = false;
    this.trackableItensList = item;
    this.talhaoList = [];
    this.harvestList = [];
    this.culturesList = [];
    item?.forEach(movement => {
      const harvest = movement.item ? this.getFeatureByName(movement.item, 'ANO_SAFRA') || this.getFeatureByName(movement.item.product, 'Ano Safra') : '';
      if (harvest && !this.harvestList.includes(harvest)) {
        this.harvestList.push(harvest);
      }

      // const culture = movement.item ? this.getFeatureByName(movement.item, 'CULTURA') || this.getCultureName(movement.item.product.code) : '';
      //if (culture && !this.culturesList.includes(culture)) {
      // this.culturesList.push(culture);
      //}

      //const talhao = movement.item && this.verificaSubPlace(this.getFeatureByName(movement.item, 'NOME_TALHAO')) ? this.getFeatureByName(movement.item, 'NOME_TALHAO') : '';
      //if (talhao && !this.talhaoList.includes(talhao)) {
      //  this.talhaoList.push(talhao);
      // }
    });
  }

  verificaSubPlace(nomeTalhao: string | null): boolean {
    const farm = this.farms.find((item: any) => item.code === this.formFilter.get('farm')?.value);
    return (nomeTalhao && farm && farm.subPlaces.some((item: any) => item.nickname === nomeTalhao));
  }


  getCultureName(productCode: string): string {
    let culture = '-'
    if (productCode) {
      const splittedCode = productCode.split('-');
      culture = splittedCode[0];
    }
    return culture;
  }
  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: null,
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  addNItem() {
    let i = 0;
    this.returnedTrace.forEach((rt: any) => {
      rt.item.nItem = i;
      i++;
    })
  }

  getLandingPageData() {
    let totalValue = 0;
    let farmList: any[] = [];
    let verificationDate = '';
    let cultura = '';
    let harvest = '';
    let biomes: any[] = [];
    let preservedArea = '';
    let soybeanArea = '';
    let nationalAverage = 0;
    let averageEstimate = 0;
    let namesBiomes: any[] = [];
    let qtdBiomes = 0;

    let divider = 0;
    let dividend = 0;
    let enterprise: any = null;

    this.isLoading = true;
    this.enterpriseService.getEnterpriseByCustomerId(this.userId).subscribe(item => {
      this.isLoading = false;
      enterprise = item[0];
      divider = 0;
      dividend = 0;

      enterprise.places.map((place: any) => {
        if (place.type === 'FARM') {
          farmList.push({
            nickname: place.nickname,
            code: place.code,
            ruralEnvironmentalRegistry: place.ruralEnvironmentalRegistry,
          });
        }
      })
      totalValue = 0;
      this.traceabilityService.getTraceByDocument(enterprise.document).subscribe(trackeableItens => {
        const culturaFilter = this.formFilter.get('culture')?.value;
        const harvestFilter = this.formFilter.get('harvest')?.value;
        const filteredTrackableItens = trackeableItens.filter(item => this.getFeatureByName(item.item, 'CULTURA') === culturaFilter && this.getFeatureByName(item.item, 'ANO_SAFRA') === harvestFilter);
        filteredTrackableItens[0].item.features.map(item => {
          if (item.name === 'DATA_REGISTRO') {
            verificationDate = item.value;
          }
          if (item.name === 'CULTURA') {
            cultura = item.value;
          }
          if (item.name === 'ANO_SAFRA') {
            harvest = item.value;
          }
        });

        filteredTrackableItens.forEach(trackeableItem => {
          biomes.push(trackeableItem.item.features.filter(f => f.name === "BIOMA"));

          let featurePreservedArea = trackeableItem.item.features.filter(f => f.name === "AREA_PRESERVADA");
          preservedArea += parseFloat(featurePreservedArea[0].value);

          let soybean = trackeableItem.item.features.filter(f => f.name === "AREA_SOJA");
          soybeanArea += parseFloat(soybean[0].value);


          if (trackeableItem.item.features.some(f => f.name === "ANO_SAFRA" && f.value === harvest)) {
            totalValue += trackeableItem.item.receivedQuantity;
          }


          let emissaoCarbono = 0;
          //let areaSoja = 0;
          trackeableItem.item.features.forEach(feature => {
            if (feature.name === "QTD_TOTAL_EMISSÃO_CARBONO" || feature.name ==='QTD_TOTAL_EMISSÃO_CARBONO') {
              emissaoCarbono = parseFloat(feature.value);
            }
          });
          divider += trackeableItem.item.receivedQuantity;
          dividend += (emissaoCarbono * trackeableItem.item.receivedQuantity);

        })

        nationalAverage = dividend / divider;
        let feature = filteredTrackableItens[0].item.features.filter(f => f.name === 'PEGADA_MEDIA_SOJA_COMUM')
        averageEstimate = parseFloat(feature[0].value);
        biomes.forEach(biome => {
          namesBiomes.push(biome[0].value);
        })

        let i = 0, biomesDiferents: string[] = [];
        biomesDiferents.push(namesBiomes[0]);

        namesBiomes.forEach(nameBiome => {
          if (namesBiomes[i] != nameBiome && !biomesDiferents?.includes(nameBiome)) {
            biomesDiferents.push(nameBiome);
          }
        })

        qtdBiomes = biomesDiferents?.length;


        const pageData = {
          customerId : this.userId,
          cultura: cultura,
          harvest: harvest,
          volumeTotal: totalValue,
          pegadaMediaGrupo: nationalAverage,
          farmList: farmList,
          biomes: biomesDiferents,
          verificationDate: verificationDate,
        }

        let token = '';

        this.qrCodeService.postQRData({ data: pageData }).subscribe((res : any) => {
          token = res.id;
          this.urlLandingPage = `/landing-page?data=${token}`;
          this.urlQRCODE = `${environment.apiURL}/qrcode?url=${this.urlQrCodeTrader}/landing-page?data=${token}`;
        });
      });
    }, error => {

    });
  }

}
